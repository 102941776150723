import React from "react"
import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"

const SEO = ({ children, description, lang, meta, title, image, path }) => {
  const { saleor } = useStaticQuery(query)

  const metaTitle = title || saleor.shop.name
  const metaImage = image || saleor.shop.image
  const metaDescription = description || saleor.shop.description
  const metaUrl = path
    ? `${saleor.shop.domain.url}${path}/`
    : `${saleor.shop.domain.url}`

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          content: `width=device-width, initial-scale=1`,
          name: `viewport`,
        },
        {
          content: `Ecommerce, Store, Storefront, Shop`,
          name: `keywords`,
        },
        {
          content: "Inkoop",
          name: `author`,
        },
        {
          content: metaImage,
          name: `image`,
        },
        {
          content: metaDescription,
          name: `description`,
        },
        {
          content: saleor.shop.name,
          name: "application-name",
        },
        // Schema.org for Google
        {
          content: saleor.shop.name,
          itemprop: "name",
        },
        {
          content: metaDescription,
          itemprop: "description",
        },
        {
          content: metaImage,
          itemprop: "image",
        },
        // Open Graph
        {
          content: metaUrl,
          property: `og:url`,
        },
        {
          content: metaTitle,
          property: `og:title`,
        },
        {
          content: metaDescription,
          property: `og:description`,
        },
        {
          content: `website`,
          property: `og:type`,
        },
        {
          content: metaImage,
          property: `og:image`,
        },
        {
          href: metaUrl,
          name: "canonical",
        },
        {
          href: metaUrl,
          hreflang: "en",
          name: "alternate",
        },
        {
          href: metaUrl,
          hreflang: "x-default",
          name: "alternate",
        },
      ].concat(meta)}
    >
      {children}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href={
          process.env.GATSBY_APP_FONT_FAMILY_URL ||
          "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;700&display=swap"
        }
        rel="stylesheet"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const query = graphql`
  query shopInformation {
    saleor {
      shop {
        name
        description
        domain {
          url
          sslEnabled
        }
      }
    }
  }
`

export default SEO
